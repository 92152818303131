/* =============================================
  sidebar
============================================= */
.l-header {

  &-logo {
    &-area {
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $color-navi-border;

      @include is-humberger() {
        height: 45px;
        padding-left: 15px;
        justify-content: flex-start;
        border-bottom: 0;
        position: relative;
        z-index: 20;
        width: 150px;
      }
    }

    &-img {
      width: 167px;
      display: block;

      @include is-humberger() {
        width: 140px;
      }
    }
  }

  &-navi {
    
    @include is-humberger() {
      display: none;
    }

    &-area {
      position: relative;

      @include is-humberger() {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 45px;
      }

      .-admin {
        @include is-humberger() {
          display: none;
        }
      }
    }

    a {
      color: $white;
    }

    &-item {
      border-bottom: 1px solid $color-navi-border;

      &-link {
        width: 100%;
        display: flex;
        height: 50px;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;

        &.-icon {
          .-text {
            display: flex;
            align-items: center; 

            &::before {
              content: "";
              display: block;
              width: 25px;
              height: 25px;
              background-size: 24px 24px;
              background-position: center;
              background-repeat: no-repeat;
              margin-right: 10px;
            }
          }

          &.-call-log {
            .-text {
              &::before {
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2034%2043%22%3E%3Cpath%20d%3D%22M26.2%2043c-3.2%200-7-1.6-9.9-3.8-3.9-3-7.3-6.6-10-10.7S1.7%2019.9.5%2015.1C-.6%2010.2%200%204%203.3%201.9%204.7%201%208.3-.2%209.2%200l.4.1%206%209.1c1%201.5-1.5%203.8-2.1%204.2-1.1.7-2.4%201.2-3.8%201.3-.2.5-.2%201-.1%201.6v.7c0%201.5%201.1%204%203.2%207.4%202.2%203.2%204.1%205.2%205.5%205.8.2.1.5.2.7.3.4.3%201%20.5%201.5.5.7-1.1%201.7-2.1%202.8-2.8.6-.3%203.7-1.7%204.7-.2l6%209.1-.1.4c-.2%201-2.8%203.7-4.1%204.6-1.1.6-2.3.9-3.6.9zM8.5%202.2c-1.4.3-2.8.8-4%201.4-2.1%201.4-3%206.3-1.9%2011%201.1%204.5%202.9%208.8%205.5%2012.7%202.6%203.9%205.8%207.3%209.5%2010.1%203.9%202.9%208.8%204.2%2011%202.8%201.1-.8%202.1-1.9%203-3l-5.2-7.9c-.7.1-1.4.3-2%20.6-.9.6-1.6%201.5-2.2%202.4l-.2.4-.2.1c-1.3.7-2.4.2-3.7-.4l-.6-.3c-1.8-.8-3.8-2.9-6.4-6.6-2.4-3.9-3.5-6.6-3.5-8.5v-.7c0-1.4-.1-2.6%201.1-3.5l.2-.2h.3c1.1-.1%202.1-.5%203.1-1%20.6-.4%201-1%201.4-1.6L8.5%202.2zM31.8%2037z%22%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E')
              }
            }
          }

          &.-recall-list {
            .-text {
              &::before {
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2239%22%20height%3D%2243%22%3E%3Cpath%20data-name%3D%22%E3%82%B7%E3%82%A7%E3%82%A4%E3%83%97%2012%22%20d%3D%22M19.5%200a19.556%2019.556%200%2000-5.713%2038.242L12.543%2043l12.014-3.287-8.846-8.786-1.4%205.334a17.484%2017.484%200%201115.272-2.434l1.845%201.178A19.549%2019.549%200%200019.5%200z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')
              }
            }
          }

          &.-report {
            .-text {
              &::before {
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2252%22%20height%3D%2243.594%22%3E%3Cdefs%3E%3Cstyle%3E.cls-2%7Bfill%3A%23fff%3Bfill-rule%3Aevenodd%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M43.3%2041.281V17.738h-2.292v23.543h-8V25.587h-2.294v15.694h-8V20.587h-2.293v20.694h-8v-11.8h-2.293v11.8H0v2.313h52v-2.31h-8.7z%22%2F%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M51.768%2010.741l-2.606-.178.532-7.745-7.761-.224.075-2.611%2010.478.3zM7.721%2026.508l-1.755-1.934L22.375%209.685a1.3%201.3%200%20011.868.116l6.924%208.063L49.87.894l1.755%201.935-19.7%2017.873a1.306%201.306%200%2001-1.869-.117l-6.924-8.063z%22%2F%3E%3C%2Fsvg%3E')
              }
            }
          }

          &.-admin-ttl {
            .-text {
              &::before {
                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.156%22%20height%3D%2241.344%22%3E%3Cpath%20d%3D%22M32.612%2037.254a1.006%201.006%200%2001-1.006-1.006V18.105c-.055-1.483-2.812-2.638-6.713-2.81-4.684-.207-9.132%201.128-9.409%202.81v17.892a1.006%201.006%200%2011-2.011%200V18.036a.969.969%200%2001.007-.116c.438-3.754%207.117-4.828%2011.5-4.635%205.251.232%208.56%202.062%208.636%204.776V36.248a1.006%201.006%200%2001-1.004%201.006zm-31.607%202.08a1.006%201.006%200%2001-1.006-1.006V23.05a.969.969%200%2001.007-.116c.345-2.956%204.978-4.268%209.467-4.268h.144a1.006%201.006%200%2001-.006%202.012h-.006a16.671%2016.671%200%2000-5.4.786c-1.3.459-2.091%201.06-2.2%201.657v15.204a1.006%201.006%200%2001-1%201.009zm45.136%202.012a1.006%201.006%200%2001-1.006-1.006V23.125c-.105-.6-.9-1.2-2.2-1.657a16.707%2016.707%200%2000-5.4-.786h.001a1.006%201.006%200%2001-.006-2.012h.144c4.489%200%209.122%201.312%209.467%204.268a.969.969%200%2001.007.116v17.29a1.006%201.006%200%2001-1.007%201.002zM7.524%2016.007a5.281%205.281%200%20115.282-5.282%205.287%205.287%200%2001-5.282%205.282zm0-8.551a3.27%203.27%200%20103.27%203.269%203.273%203.273%200%2000-3.27-3.269zm31.833%208.551a5.281%205.281%200%20115.282-5.282%205.287%205.287%200%2001-5.282%205.282zm0-8.551a3.27%203.27%200%20103.27%203.269%203.273%203.273%200%2000-3.27-3.269zm-15.812%203.122a5.282%205.282%200%20115.281-5.281%205.287%205.287%200%2001-5.281%205.281zm0-8.551a3.27%203.27%200%20103.27%203.27%203.274%203.274%200%2000-3.27-3.272z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E')
              }
            }
          }
        }
      }
    }

    .-admin & {
      background-color: $sub;

      &-item {
        border-bottom: 1px solid $color-navi-border;
        margin-left: 60px;
        margin-right: 35px;
        color: $white;

        &-link {
          padding-left: 0;
        }

        &:first-child {
          margin-left: 0;
          margin-right: 0;
          border-bottom: 1px solid $color-navi-border2;

          .l-header-navi-item-link {
            padding-left: 24px;

            .-text {
              font-weight: bold;
            }
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  &-sp-block {
    display: none;

    @include is-humberger() {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 45px;
      height: 45px;
      z-index: 200;
      background-color: $sub;
      transition: background-color .2s ease; 
    }

    .p-burger-icon {
      display: block;
      height: 45px;
      width: 45px;
      position: relative;

      &-line {
        position: absolute;
        width: 20px;
        display: block;
        height: 0;
        left: 12.5px;
        border-top: 1px solid $white;
        transition: all .2s ease;
        
        &:first-child {
          margin-top: -5px;
        }

        &:last-child {
          margin-bottom: -5px;
          width: 15px;
        }
      }
    }

    body[data-status='is-menu-active'] & {
      background-color: $main;

      .p-burger-icon {
        &-line {        
          &:first-child {
            margin-top: 0;
            width: 15px;
            transform: translateY(-2.5px) rotate(45deg);
          }
  
          &:last-child {
            margin-bottom: 0;
            transform: translateY(-2px) rotate(-45deg);
          }
        }
      }
    }
  }
}

.p-user-info {
  &-box {
    display: none;
    position: absolute;
    background-color: $white;
    min-width: 240px;
    top: 45px;
    right: 45px;

    .l-main-header & {
      top: 60px;
    }

    .-user.js-link:hover & {
      display: block;
    }

    &-dl {
      display: flex;
      width: 100%;
      line-height: 1.2;
    }

    &-dt {
      padding: 5px 10px;
      min-width: 9em;
      background-color: $main;
      color: $white;
    }

    &-dd {
      padding: 5px 10px;
      color: $black2;
    }

    &-close {
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 12px;
        height: 0;
        border-top: 1px solid $black2;
        left: 4px;
        top: 50%;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &-sp {
    display: none;
    padding-right: 65px;
    justify-content: flex-end;
    align-items: center;
    height: 45px;
    color: $white;

    @include is-humberger() {
      display: flex;    
    }

    .l-main-header-menu-item-link  {
      color: $white;
    }
  }
}

.p-menu-sp-navi {
  position: absolute;
  top: 0;
  right: -320px;
  width: 320px;
  background-color: $main;
  transition: right .2s ease;

  body[data-status='is-menu-active'] & {
    right: 0;
  }

  body[data-status='is-menu-off'] & {
    right: -320%;
  }

  &-header {
    height: 45px;
    background-color: $gray01;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  &-area {
    overflow: hidden;
    position: fixed;
    top: 45px;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 19999;
    background-color: rgba($black, .5);

    .l-header-navi {
      display: block;
    }


    body &,
    body[data-status='is-menu-off'] & {
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s ease, visibility .2s ease .2s;
    }

    body[data-status='is-menu-active'] & {
      opacity: 1;
      visibility: visible;
      overflow-y: auto;
      transition: opacity .2s ease, visibility .2s ease;
    }  
  }
}

body[data-status='is-menu-active'] {
  overflow: hidden;
}
