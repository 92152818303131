
/* =============================================
  main area
============================================= */

.l-main {
  &-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include is-humberger {
      display: none;
    }

    &-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &-item {
        margin-right: 30px;

        &-link {
          text-decoration: underline;

          &.-user {
            display: block;

            &:hover {
              opacity: 1;
            }

            .-text {
              display: flex;
              align-items: center; 
        
              &::before {
                content: "";
                display: block;
                width: 30px;
                height: 30px;
                background-size: 30px 30px;
                background-position: center;
                background-repeat: no-repeat;
                margin-right: 10px;

                background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2262%22%20height%3D%2262%22%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%201%22%20cx%3D%2231%22%20cy%3D%2231%22%20r%3D%2231%22%20fill%3D%22%230d87b8%22%2F%3E%3Cpath%20data-name%3D%22%E3%82%B7%E3%82%A7%E3%82%A4%E3%83%97%201%22%20d%3D%22M34.96%2035.135a10.77%2010.77%200%2010-7.92%200c-6.39%201.63-11.1%207.1-11.1%2013.576h2.96c0-6.116%205.43-11.091%2012.1-11.091s12.1%204.976%2012.1%2011.091h2.96c0-6.478-4.71-11.945-11.1-13.576zm-11.77-10.1A7.81%207.81%200%201131%2032.921a7.855%207.855%200%2001-7.81-7.882z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');

                @include is-humberger() {
                  width: 25px;
                  height: 25px;
                  background-size: 25px 25px;
                }
              }
            }
          }
        }
      }
    }

  }
}
