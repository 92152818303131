.p-widget {
  border-top: 2px solid #30b9f0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 21400;

  &.-appoint_info {
    width: 370px;

    @include is-mobile{
      width: 240px;
      right: 0;
    }
  }
  
  h3.u-ttl {
    display: flex;
    height: 50px;
    padding-left: 20px;
    -webkit-box-align: center;
    align-items: center;

    @include is-mobile{
      padding-left: 10px;
      height: 40px;
    }
  }
  
  &-content {
    padding: 20px 20px 30px;
    border-top: 1px solid #d0e0e7;
    word-break: break-all;

    @include is-mobile{
      padding: 10px 10px 20px;
    }
    
    &-desc {
      min-width: 100%;
      border-bottom: 1px solid #d0e0e7;
      padding-bottom: 10px;
      margin-bottom: 10px;
      line-height: 1.4;

      &-ttl {
        font-weight: bold;
        color: $main;
      }

      &-txt {
        display: inline-block;
        font-size: 2em;
        font-weight: bold;

        & + & {
          margin:0 1em;

          @include is-mobile{
            margin:0;
          }
        }
      }
    }
  }

  &-list {
    overflow: hidden;
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 500px);

    .-appoint_info & {

      &.close {
        height: 0;
      }

      &-toggle {
        position: relative;
        color: $main;
        font-size: .95em;

        &::after {
          position: absolute;
          top: 2px;
          right: -16px;
          display: block;
          width: 6px;
          height: 6px;
          border-right: 1px solid #333333;
          transform: rotate(45deg);
          border-bottom: 1px solid #333333;
          content: "";
        }

        &.close::after {
          top: 7px;
          transform: rotate(-135deg);
        }
      }
    }

    &-item {
      padding: .25em;

      &:nth-child(even) {
        background-color: #f3f9fb;
      }

      &.entry {
        color: $red;
      }

      & + & {
        margin-top: .1em;
      }

      &-row {
        word-break: break-all;
        line-height: 1.1;
        font-size: .8em;

        & + & {
          margin-top: .2em;
        }

        &.-justify {
          overflow: hidden;
          .-recall-complete {
            float: right;
          }
        }
      }

      &-txt {
        display: inline-block;
        font-size: 1.3em;
        font-weight: bold;

        & + & {
          margin:0 1em;
        }
      }
    }
  }

  &.-anchor-box {
    right: auto;
    left: 230px;

    @include is-humberger() {
      left: 10px;
    }

    .p-widget-content {
      padding: 10px;

      .-select-user-anchor {
        margin-right: 1em;

        .p-data-checkbox {
          display: inline-block;
          position: relative;
          vertical-align: baseline;
          margin: 0 0 -1px 2px;

          input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      
      .-open_select_user_report {
        color: $main;

        &::after {
          content: '';
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2245%22%20height%3D%2233.09%22%3E%3Cpath%20d%3D%22M8%201.99h35v23.1H8V1.99z%22%20fill%3D%22none%22%20stroke%3D%22%230d87b8%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%2F%3E%3Cpath%20d%3D%22M0%2010.09h4v19H0v-19zm0%2019h24v4H0v-4z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
          background-size: 22px 16px;
          width: 22px;
          height: 16px;
          margin-left: 5px;
        }
      }
    }
  }

  .p-widget-close {
    position: absolute;
    top: 15px;
    right: 25px;
    display: block;
    width: 10px;
    height: 10px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);

    @include is-mobile{
      top: 11px;
    }

    &.close {
      top: 23px;
      transform: rotate(-45deg);

      @include is-mobile{
        top: 16px;
      }
    }
    
    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 0;
      border-top: 1px solid #333333;
      content: "";
    }

    &::after {
      top: 0;
      left: 10px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      -webkit-transform-origin: left;
      transform-origin: left;
    }
  }

  .-flash {
    animation: flash 1s linear infinite;
  }
  
  .-alert {
   color: $red;
   font-weight: bold;
  }

  @keyframes flash {
    0%,100% {
      color: $black;
    }
  
    50% {
      color: $red;
    }
  }
  
}