
/*----------------------------------------
parts - chceckbox
----------------------------------------*/
.p-data {

  // フォームパーツ：チェックボックス
  &-checkbox {
    height: 12px;
    display: flex;
    align-items: center;
    margin-right: 12px;

    label {
      width: 12px;
      min-width: 12px;
      max-width: 12px;
      height: 12px;
      border: 1px solid $gray07;
      display: block;
      position: relative;
      line-height: 1;
      padding-right: 0;
    }

    input[type='checkbox']:checked + label::before {
      top: 0;
      left: 0;
      display: block;
      width: 10px;
      height: 10px;
      background-size: 10px 10px;
      font-size: 11px;
      position: absolute;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20fill%3D%22%230d87b8%22%20d%3D%22M9.6%202.5l-.8-.9-5.2%205.1-2.4-2.6-.8.8%203.1%203.4h.1z%22%2F%3E%3C%2Fsvg%3E');
      content: "";
    }
  }
}