
/*----------------------------------------
Variables
----------------------------------------*/

/*

# Color */
$black: #000000;
$black2: #333333;
$white: #fff;
$select: #cfe7f1;
$gray01: #e0e0e0;
$gray02: #cccccc;
$gray03: #9c9c9c;
$gray04: #e3e3e3;
$gray05: #d0e0e7;
$gray06: #e5e5e5;
$gray07: #666666;
$gray08: #999999;
$gray09: #f5f5f5;
$gray10: #d5d5d5;
$gray11: #444444;

$red: #cc0000;
$pink: #ffe8e8;
$orange: #f08000;

$main: #0d87b8;
$sub: #126585;
$paleblue: #f3f9fb;

$main-bg: #f2f2f2;

$input-telno-color: #0d87b8;
$input-telno-color-bg: #cfe7f1;

$table-tr-even: #f3f9fb;
$table-tr-bottom: #dbedf4;

/*# Border */
$color-border: #ddd;
$color-navi-border: #80bdd5;
$color-navi-border2: #3f819b;
$color-pain-border: #30b9f0;
$color-ttl-border: #d0e0e7;

/*

# Scroll-bar */
$color-track: #f1f1f1;
$color-thumb: #c1c1c1;

/*

# Shadow */
$shadow: rgba(#777, .1);
$shadow-hover: rgba(#777, .3);

/*

# Hover */
$opacity: .7;


/*

# Width */
$design-width: 1600px;
$outer-width: 1200px;
$content-width: 1000px;

/*

# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */
$ease-in-sine: cubic-bezier(.47, 0, .745, .715);
$ease-out-sine: cubic-bezier(.39, .575, .565, 1);
$ease-in-out-sine: cubic-bezier(.445, .05, .55, .95);
$ease-in-quad: cubic-bezier(.55, .085, .68, .53);
$ease-out-quad: cubic-bezier(.25, .46, .45, .94);
$ease-in-out-quad: cubic-bezier(.455, .03, .515, .955);

$ease-in-out-quad: cubic-bezier(.55, .055, .675, .19);
$ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
$ease-in-out-quad: cubic-bezier(.645, .045, .355, 1);
$ease-in-quart: cubic-bezier(.895, .03, .685, .22);
$ease-out-quart: cubic-bezier(.165, .84, .44, 1);
$ease-in-out-quart: cubic-bezier(.77, 0, .175, 1);

$ease-in-quint: cubic-bezier(.755, .05, .855, .06);
$ease-out-quint: cubic-bezier(.23, 1, .32, 1);
$ease-in-out-quint: cubic-bezier(.86, 0, .07, 1);
$ease-in-expo: cubic-bezier(.95, .05, .795, .035);
$ease-out-expo: cubic-bezier(.19, 1, .22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

$ease-in-circ: cubic-bezier(.6, .04, .98, .335);
$ease-out-circ: cubic-bezier(.075, .82, .165, 1);
$ease-in-out-circ: cubic-bezier(.785, .135, .15, .86);
$ease-in-back: cubic-bezier(.6, -.28, .735, .045);
$ease-out-back: cubic-bezier(.175, .885, .32, 1.275);
$ease-in-out-back: cubic-bezier(.68, -.55, .265, 1.55);
/*




$_: #506169;
$_: #788b8f;
$_: #333a42;
$_: #3d3c4e;
$_: #b1afa2;
$_: #63334d;
$_: #5f3565;
$_: #78477f;
$_: #6c54aa;
$_: #aa7aa4;
$_: #b1aeb5;
$_: #f0a5bc;
$_: #ed8495;
$_: #fb9b83;
$_: #fc8cb2;
$_: #f03a6d;
$_: #fd1c46;
$_: #d81b5f;
$_: #c3000d;
$_: #f84548;
$_: #7e0b2c;
$_: #d92d07;
$_: #283929;
$_: #0b512c;
$_: #74ad46;
$_: #458a49;
$_: #97b241;
$_: #72ae68;
$_: #817722;
$_: #ea681a;
$_: #fd980c;
$_: #edcf0d;
$_: #faef96;
$_: #ffdb91;
$_: #f1cd83;
$_: #dacba0;
$_: #d58760;
$_: #d88745;
$_: #9b6213;
$_: #ef9b39;
$_: #d88027;
$_: #d1622b;
$_: #9d5c36;
$_: #765242;
$_: #141b2e;
$_: #488cc9;
$_: #55c0ee;
$_: #1c6e7a;
$_: #2a5578;
$_: #4f6067;*/