
/*----------------------------------------
parts - textarea
----------------------------------------*/

.p-data {
  &-listcontrolle {
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    position: relative;

    @include is-mobile {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &-limitselectbox {
    display: flex;
    
    @include is-mobile {
      flex-wrap: wrap;
    }

    label {
      margin-right: 15px;
    }
  }

  &-selectbox {
    min-width: 45px;
    width: auto;
    background: none;
    cursor: pointer;

    &#condition_select {
      height: 25px;
      padding: 0 10px;
      font-size: 12px;
      border: 1px solid $black;
    }

    &-wrapper {
      position: relative;
      display: inline-block;
      line-height: 2;

      &.-wide {
        display: block;
        width: calc(100% - 110px);
      }


      &::after {
        content: "";
        z-index: -1;
        width: 5px;
        height: 5px;
        border-right: 1px solid $main;
        border-bottom: 1px solid $main;
        transform: rotate(46deg) skewX(4deg);
        display: block;
        position: absolute;
        top: 7px;
        right: 7px;
      }
    }
  }

  &-pagenation {
    $link: &;

    display: flex;

    &-area {
      display: flex;
      
      @include is-mobile {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &-desc {
      margin-right: 20px;

      @include is-mobile {
        margin-right: 10px;
        margin-left: 10px
      }

      &-txt {
        font-weight: bold;
      }
    }

    &-link,
    &-list .paginationjs-page {
      .-disabled & {
        opacity: .3;
        pointer-events: none;
      }
    }

    &-list-ul {
      display: flex;
    }

    &-tofirst {
      #{$link}-link {
        display: inline-block;
        width: 15px;
        height: 23px;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2027.9%2026.3%22%3E%3Cstyle%3E.st0%7Bfill%3A%23333%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M13%2013.4l12.9%2012.9%202-2-11.1-11.2L27.9%202l-2-2L13%2012.8z%22%2F%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%2013.4l12.9%2012.9%202-2L3.8%2013.1%2014.9%202l-2-2L0%2012.8z%22%2F%3E%3C%2Fsvg%3E');
        background-size: 14px 13px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.-disabled a {
        opacity: .3;
        pointer-events: none;
      }
    }

    &-prev,
    &-list .paginationjs-prev {
      #{$link}-link,
      a {
        display: inline-block;
        width: 20px;
        height: 23px;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014.9%2026.3%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M0%2013.4l12.9%2012.9%202-2L3.8%2013.1%2014.9%202l-2-2L0%2012.8z%22%2F%3E%3C%2Fsvg%3E');
        background-size: 7.5px 13px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.disabled a{
        opacity: .3;
      }
    }

    &-toend {
      #{$link}-link {
        display: inline-block;
        width: 15px;
        height: 23px;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2027.9%2026.3%22%3E%3Cstyle%3E.st0%7Bfill%3A%23333%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M14.9%2012.8L2%200%200%202l11.1%2011.1L0%2024.3l2%202%2012.9-12.9z%22%2F%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M27.9%2012.8L15%200l-2%202%2011.1%2011.1L13%2024.3l2%202%2012.9-12.9z%22%2F%3E%3C%2Fsvg%3E');
        background-size: 14px 13px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.-disabled a {
        opacity: .3;
        pointer-events: none;
      }
    }

    &-next,
    &-list .paginationjs-next {
      #{$link}-link,
      a {
        display: inline-block;
        width: 20px;
        height: 23px;
        background-color: $white;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014.9%2026.3%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M14.9%2012.8L2%200%200%202l11.1%2011.1L0%2024.3l2%202%2012.9-12.9z%22%2F%3E%3C%2Fsvg%3E');
        background-size: 7.5px 13px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.disabled a{
        opacity: .3;
      }
    }

    &-page span,
    &-list .paginationjs-page a {
      font-weight: bold;
      padding-left: 2px;
      padding-right: 2px;
      color: $gray08;
    }

    &-page.is-current span,
    &-list .paginationjs-page.is-current a {
      color: $black2;
    }
  }
}