
/* =============================================
  LAYOUT
============================================= */

.l-wrapper {
  width: 100%;
  display: flex;
  min-height: 100vh;
  background-color: $main-bg;

  @include is-humberger() {
    display: block;
    padding-top: 45px;
    min-height: calc(100vh - 45px);
    padding-bottom: 60px;
    
    .-user_report & {
      padding-top: 0;
    }
  }
}

.l-header {
  width: 218px;
  min-width: 218px;
  background-color: $main;
  color: $white;
  z-index: 20000;
  
  @include is-humberger() {
    width: 100%;
    min-width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.l-main {
  background-color: $main-bg;
  width: 100%;

  &-header {
    background-color: $gray01;
    height: 66px;
    width: 100%;

    @include is-humberger() {
      height: 45px;
    }
  }

  &-contents {
    padding: 40px;
    min-height: calc(100vh - 45px);
    box-sizing: border-box;
    
    @include is-mobile() {
      padding: 10px;
    }

    .-user_report & {
      min-height: 100vh;
      padding: 10px;
    }
  }
}

.l-pain {
  & + .l-pain {
    margin-top: 40px;
  }

  &-child {
    background-color: $white;
    border-top: 2px solid $color-pain-border;
    
    & + .l-pain-child {
      margin-top: 40px;
    }
  }

  &.-two-column {
    display: flex;
    margin-bottom: 40px;

    @include is-mobile {
      flex-wrap: wrap;
    }

    .l-pain-child {
      width: 100%;

      & + .l-pain-child {
        margin: 0;
        margin-left: 20px;

        @include is-mobile {
          margin-left: 0;
          margin-top: 20px;
        }
  
      }
    }
  }
}