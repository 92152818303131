
/*----------------------------------------
parts - button
----------------------------------------*/
.p-data {

  &-utilbox {
    width: 100%;
    position: absolute;
    top: 16px;

    .u-ttl + & {
      @include is-mobile {
        position: relative;
        top: auto;

        .p-data-btn-area {
          position: relative;
          top: auto;
          margin-bottom: 20px;
        }
      }
    }

    &.-notitle {
      position: relative;
      height: 80px;
      z-index: 0;
      
      @include is-mobile {
        position: relative;
        top: auto;
        height: auto;
      }
    }
  }

  // フォームパーツ：ボタン
  &-btn {
    $this: &;

    display: inline-block;
    height: 25px;
    color: $white;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 2;
    background-color: $main;
    border: 1px solid $main;
    overflow: hidden;
    vertical-align: middle;

    @include is-mobile {
      margin-bottom: 10px;
    }

    &::before {
      display: block;
      float: left;
      width: 23px;
      height: 23px;
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
    }

    span {
      padding: 0 10px;
    }

    &box {
      display: inline-block;
      vertical-align: top;
    }

    &-area {
      top: 0;
      position: absolute;

      @include is-mobile {
        position: relative;
        top: auto;
      }

      #{$this}box + #{$this}box {
        margin-left: 20px;

        @include is-mobile {
          margin-left: 0;
        }
    
      }

      &.-left {
        text-align: left;
        left: 0;
        padding-left: 20px;
        height: 44px;
        width: 100%;
        border-bottom: 1px dotted $main;

        @include is-mobile {
          height: auto;
          margin-top: 15px;
          text-align: center;
          border-bottom: 0;
        }
      }

      &.-right {
        text-align: right;
        right: 0;
        padding-right: 20px;
        
        @include is-mobile {
          right: auto;
          padding-right: 0;
          padding-left: 20px;
          text-align: center;
          height: auto;
          border-bottom: 0;
        }
      }
    }

    // プラスアイコン
    &.-add {
      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%230d87b8%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Crect%20class%3D%22cls-1%22%20y%3D%2211%22%20width%3D%2224%22%20height%3D%222%22%2F%3E%20%3Crect%20class%3D%22cls-1%22%20x%3D%2211%22%20width%3D%222%22%20height%3D%2224%22%2F%3E%3C%2Fsvg%3E');
        background-size: 12px 12px;
      }
    }


    // 検索アイコン
    &.-search {
      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2227.56%22%20height%3D%2228.06%22%20viewBox%3D%220%200%2027.56%2028.06%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%230d87b8%3B%20fill-rule%3A%20evenodd%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M1252.21%2C1372.58l-8.03-7.96a11.118%2C11.118%2C0%2C0%2C0%2C3.03-7.63%2C11.276%2C11.276%2C0%2C1%2C0-4.36%2C8.83l8.09%2C8.02Zm-25.78-15.59a9.5%2C9.5%2C0%2C1%2C1%2C9.5%2C9.42A9.473%2C9.473%2C0%2C0%2C1%2C1226.43%2C1356.99Z%22%20transform%3D%22translate(-1224.66%20-1345.78)%22%2F%3E%3C%2Fsvg%3E');
        background-size: 14px 14px;
      }
    }

    // エクスポートアイコン
    &.-export {
      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2227.94%22%20height%3D%2218.62%22%20viewBox%3D%220%200%2027.94%2018.62%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%230d87b8%3B%20fill-rule%3A%20evenodd%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M2521.56%2C1357.72a8.727%2C8.727%2C0%2C0%2C0-16.31-2.33%2C6.985%2C6.985%2C0%2C0%2C0%2C.76%2C13.93h15.15A5.806%2C5.806%2C0%2C0%2C0%2C2521.56%2C1357.72Zm-0.4%2C9.27h-15.15a4.659%2C4.659%2C0%2C0%2C1-.51-9.29l1.24-.12%2C0.59-1.11a6.391%2C6.391%2C0%2C0%2C1%2C11.95%2C1.7l0.35%2C1.75%2C1.78%2C0.13A3.474%2C3.474%2C0%2C0%2C1%2C2521.16%2C1366.99Zm-6.47-9.32h-3.38v3.5h-2.97l4.66%2C4.66%2C4.66-4.66h-2.97v-3.5Z%22%20transform%3D%22translate(-2499.03%20-1350.69)%22%2F%3E%3C%2Fsvg%3E');
        background-size: 15px 10px;
      }
    }

    // インポートアイコン
    &.-import {
      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228.16%22%20height%3D%2218.78%22%20viewBox%3D%220%200%2028.16%2018.78%22%3E%20%3Cdefs%3E%20%3Cstyle%3E%20.cls-1%20%7B%20fill%3A%20%230d87b8%3B%20fill-rule%3A%20evenodd%3B%20%7D%20%3C%2Fstyle%3E%20%3C%2Fdefs%3E%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M2849.75%2C1357.65a8.781%2C8.781%2C0%2C0%2C0-16.41-2.34%2C7.03%2C7.03%2C0%2C0%2C0%2C.76%2C14.02h15.24A5.847%2C5.847%2C0%2C0%2C0%2C2849.75%2C1357.65Zm-0.41%2C9.34H2834.1a4.689%2C4.689%2C0%2C0%2C1-.52-9.35l1.26-.13%2C0.58-1.11a6.435%2C6.435%2C0%2C0%2C1%2C12.03%2C1.71l0.36%2C1.76%2C1.79%2C0.13a3.494%2C3.494%2C0%2C0%2C1%2C3.26%2C3.47A3.532%2C3.532%2C0%2C0%2C1%2C2849.34%2C1366.99Zm-12.9-5.87h2.99v3.52h3.4v-3.52h2.99l-4.69-4.69Z%22%20transform%3D%22translate(-2827.06%20-1350.56)%22%2F%3E%3C%2Fsvg%3E');
        background-size: 15px 10px;
      }

      & + .csv_upload.-hide {
        width: 132px;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: -10000px;
      }
    }

    // フォーマット（ファイル）アイコン
    &.-format {
      background-color: $black2;
      border: 1px solid $black2;

      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2222%22%20height%3D%2231%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22filter%22%20filterUnits%3D%22userSpaceOnUse%22%3E%3CfeFlood%20result%3D%22flood%22%20flood-color%3D%22%23333%22%2F%3E%3CfeComposite%20result%3D%22composite%22%20operator%3D%22in%22%20in2%3D%22SourceGraphic%22%2F%3E%3CfeBlend%20result%3D%22blend%22%20in2%3D%22SourceGraphic%22%2F%3E%3C%2Ffilter%3E%3Cstyle%3E.cls-2%7Bfill%3A%230d87b8%3Bfill-rule%3Aevenodd%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20filter%3D%22url(%23filter)%22%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.39%200H-.01v31H22V7.741zm.18%201.08l6.34%206.44h-6.34V1.08zM.61%2030.368V.633h13.34v7.52h7.43v22.215H.61z%22%2F%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.39%200H-.01v31H22V7.741zm.18%201.08l6.34%206.44h-6.34V1.08zM.61%2030.368V.633h13.34v7.52h7.43v22.215H.61z%22%2F%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.39%200H-.01v31H22V7.741zm.18%201.08l6.34%206.44h-6.34V1.08zM.61%2030.368V.633h13.34v7.52h7.43v22.215H.61z%22%2F%3E%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.39%200H-.01v31H22V7.741zm.18%201.08l6.34%206.44h-6.34V1.08zM.61%2030.368V.633h13.34v7.52h7.43v22.215H.61z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        background-size: 11px 15px;
      }
    }

    // 編集アイコン
    &.-edit {
      background-color: #333333;
      border: 1px solid #333333;
      line-height: 25px;

      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228.06%22%20height%3D%2228.03%22%3E%3Cpath%20d%3D%22M.01%2022.17v5.84h5.84l17.22-17.22-5.84-5.84zM27.59%206.28a1.569%201.569%200%20000-2.2L23.94.44a1.539%201.539%200%2000-2.19%200L18.9%203.29l5.84%205.84z%22%20fill%3D%22%23333333%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-size: 15px 15px;
      }
    }

    // 削除アイコン
    &.-del {
      background-color: #333333;
      border: 1px solid #333333;
      line-height: 25px;

      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228%22%20height%3D%2228.252%22%20viewBox%3D%220%200%2027.75%2028%22%3E%3Cpath%20d%3D%22M5.135%2024.49a3.508%203.508%200%20003.49%203.5h10.5a3.508%203.508%200%20003.49-3.5V7.01H5.135v17.48zm19.23-22.73h-5.24L17.375.01h-7l-1.75%201.75h-5.24v3.5h20.98v-3.5z%22%20fill%3D%22%23333333%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-size: 14px 14px;
      }
    }

    // 同期アイコン
    &.-sync {
      background-color: $main;
      border: 1px solid $main;
      line-height: 25px;

      &::before {
        content: "";
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%230d87b8%22%20d%3D%22M370.72%20133.28C339.458%20104.008%20298.888%2087.962%20255.848%2088c-77.458.068-144.328%2053.178-162.791%20126.85-1.344%205.363-6.122%209.15-11.651%209.15H24.103c-7.498%200-13.194-6.807-11.807-14.176C33.933%2094.924%20134.813%208%20256%208c66.448%200%20126.791%2026.136%20171.315%2068.685L463.03%2040.97C478.149%2025.851%20504%2036.559%20504%2057.941V192c0%2013.255-10.745%2024-24%2024H345.941c-21.382%200-32.09-25.851-16.971-40.971l41.75-41.749zM32%20296h134.059c21.382%200%2032.09%2025.851%2016.971%2040.971l-41.75%2041.75c31.262%2029.273%2071.835%2045.319%20114.876%2045.28%2077.418-.07%20144.315-53.144%20162.787-126.849%201.344-5.363%206.122-9.15%2011.651-9.15h57.304c7.498%200%2013.194%206.807%2011.807%2014.176C478.067%20417.076%20377.187%20504%20256%20504c-66.448%200-126.791-26.136-171.315-68.685L48.97%20471.03C33.851%20486.149%208%20475.441%208%20454.059V320c0-13.255%2010.745-24%2024-24z%22%2F%3E%3C%2Fsvg%3E');
        background-size: 14px 14px;
      }
    }

    // 大きいボタン
    &.-large {
      width: 100%;
      background-color: transparent;
      border: 0;

      &::before {
        content: none;
      }

      .u-btn {
        display: flex;
        width: 100%;
        height: 63px;
        background-color: $main;
        font-size: 17px;
        font-size: 1.7rem;
        color: #fff;
        letter-spacing: .5em;
        justify-content: center;
        align-items: center;
      }
    }

    // 一括操作ボタン
    &.-bulk {
      width: auto;
      background-color: $red;
      border: 0;
      margin-left: 15px;

      &::before {
        content: none;
      }
    }

    // リセット
    &.-reset {
      background-color: $white;
      color: $black2;
      border: 1px solid $gray11;

      &::before {
        content: none;
      }
    }
  }

  // toggleアイコン
  &-toggle {
    text-align: center;
    position: relative;
    line-height: 0;
    font-size: 8px;
    width: 90px;
    margin-right: 24px;

    input[type='checkbox'] {
      position: absolute;
      box-sizing: border-box;
      height: 0;
      width: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      font-size: inherit;
      margin: 0;
      border: none;
      z-index: 0;
      cursor: pointer;
    }

    label {
      display: flex;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      width: 90px;
      height: 25px;
      background: $white;
      border: 1px solid $main;
      transition: all .3s ease;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 44px;
        height: 21px;
        background: $main;
        transition: .3s ease;
        z-index: 0;
      }

      .u-text {
        display: flex;
        height: 21px;
        align-items: center;
        justify-content: center;
        line-height: 1;
        width: 50%;
        font-size: 12px;
        font-size: 1.2rem;
        color: $main;
        position: relative;
        z-index: 1;
        transition: color .3s ease;

        &:first-child {
          color: $white;
        } 
      }
    }

    input:checked + label {
      .u-text {
        color: $white;

        &:first-child {
          color: $main;
        } 
      }
    }

    input:checked + label::after {
      background: $main;
      transform: translateX(42px);
    }
  }
}