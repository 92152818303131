/*----------------------------------------
parts - radio
----------------------------------------*/
.p-data-radio {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  input[type="radio"] + span {
    padding: 0 0 0 23px;
  }

  input[type="radio"] + span::before {
    left: -6px;
    border: 1px solid $black2;
  }

  input[type="radio"] + span::after {
    left: -2px;
    background:  $black2;
  }
}