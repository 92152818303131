
/*----------------------------------------
hover
----------------------------------------*/
a:hover, button:hover {
  opacity: .7;
}

.-zoom {
  overflow: hidden;

  img {
    transform: scale(1);
    transition: transform .3s $ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.-ad {
  transition: opacity .3s $ease;

  &:hover {
    opacity: .7;
  }
}