/*

# Font Family */
$font: "游ゴシック体", Yu-gothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic Pro-n", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$font-en: 'Open Sans', Arial, Helvetica, sans-serif;
$font-cn: "Microsoft Yahei", "Ping-hei";
$font-kr: "Malgun Gothic", "Yoon Gothic";

$font-min: "游明朝体" , "Yu Mincho" , YuMincho , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;

$font-min-jp: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;

$font-cg: 'Cormorant Garamond', serif;
$font-cg-jp : 'Cormorant Garamond',"Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho",serif;

$font-bar: 'Barlow', sans-serif;

$font-en-label: 'EB Garamond', serif;

$font-jost: 'Jost', sans-serif;

/*

# Font Weight */
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$midbold: 700;
$bold: 900;

$w300: 300;
$w400: 400;
$w500: 500;
$w600: 600;
$w700: 700;
$w900: 900;