
/* =============================================
  UTILITY
============================================= */
h2.u-ttl {
  height: 40px;
  margin-bottom: 30px;
  border-left: 7px solid $main;
  line-height: 1.1;
  display: flex;
  align-items: center;
  padding-left: 18px;

  @include fz(25);

  @include is-mobile() {
    @include fz(17);

    height: 25px;
    padding-left: 10px;
    margin-bottom: 15px;
  }
}

h3.u-ttl {
  font-weight: bold;
  line-height: 1.1;

  @include fz(15);
}

.u-shadow {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
}

.p-message {
  position: fixed;
  top: -50px;
  min-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  background-color: $orange;
  border-radius: 0px 0px 10px 10px;
  left: 50%;
  transform: translateX(-50%);
  transition: top .2s ease;
  z-index: 20001;
  font-weight: bold;
  text-align: center;

  &.-error {
    background-color: $red;
  }

  &.-is_ban {
    top: -33.5%;
    min-height: 33%;
    width: 80%;
    @include fz(24);
  }
}

.p-message.is-active {
  top: 0;
}

.-hide {
  display: none;
}


a.-disable,
button.-disable {
  pointer-events: none;
}

.-vertical-text {
  writing-mode: vertical-lr;
  text-orientation: upright;
  text-align: center;

  @include is-tablet() {
    display: block;
    width: 1em;
    min-height: 10em;
    margin: 0 auto;
  }
}