/*----------------------------------------
report
----------------------------------------*/

.p-report {
  &-area {
    width: calc(100vw - 310px);

    @include is-humberger {
      width: 100%;
    }

    .-user_report & {
      width: 100%;
    }
  }

  &-parent,
  &-child {

    &-tab {
      overflow: hidden;
  
      @include is-mobile {
        overflow-x: auto;
        white-space: nowrap;
      }
  
      &-item {
        float: left;
        
        @include is-mobile {
          float: none;
          display: inline-block;
        }

        &-link {
          display: block;
          padding: 12px 20px;
          background-color: $gray10;
          line-height: 1.1;
          font-size: 15px;
          font-size: 1.5rem;

          @include is-mobile {
            padding: 8px 16px;
            font-size: 13px;
            font-size: 1.3rem;
          }

          &.is-current {
            background-color: $color-pain-border;
            color: $white;
          }
        }

        & + & {
          margin-left: 1px;
        }

        .-btn & {
          padding: 0px 1px 1px 1px;
        }

        .-btn & + & {
          padding: 0px 1px 1px 0px;
          margin-left: 0;
        }
      }
    }
  }

  &-child {
    padding: 20px;

    @include is-mobile() {
      padding: 10px;
    }

    &-tab-item-link {
      font-size: 13px;
      font-size: 1.3rem;
      padding: 10px 12px;

      @include is-mobile {
        font-size: 12px;
        font-size: 1.2rem;
        padding: 8px 10px;

      }
    }

    &-content-data,
    &-content-userdata {
      &.-table {
        width: calc(100vw - 440px);
        overflow: auto;

        @include is-humberger {
          width: calc(100vw - 210px);
        }
      }

      &.-bar {
        width: calc(100vw - 440px);
        overflow: auto;

        @include is-humberger {
          width: calc(100vw - 210px);
        }
      }
    }

    &-content-userdata {
      padding-top: 45px;
    }
  }

  &-header {
    &-inner {
      display: flex;
      align-items: center;
    }
  }

  &-contents {
    border: 1px solid $color-pain-border;
    padding: 20px 40px;

    @include is-mobile {
      padding: 10px;
      overflow: auto;
    }
  }

  .p-data {

    &-selectbox {
      width: auto;
 
      &-area {
        display: flex;

        @include is-mobile {
          flex-wrap: wrap;
        }
      }

      &-wrapper {
        margin-right: 0;
        padding-right: 20px;
        display: block;

        &::after {
          content: none;
        }

        .select2-selection {
          padding-right: 25px;

          &::after {
            content: "";
            z-index: -1;
            width: 5px;
            height: 5px;
            border-right: 1px solid $main;
            border-bottom: 1px solid $main;
            transform: rotate(46deg) skewX(4deg);
            display: block;
            position: absolute;
            top: 7px;
            right: 7px;
          }
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
          float: none;
          margin-top: 0;
          margin-right: 0;
          padding: 0;
          border: 0;
          border-radius: 0;
          background-color: transparent;
          cursor: default;
          white-space: initial;

          & + .select2-selection__choice {
            margin-top: 0.2em;
          }

          & + .select2-search--inline {
            width: 100%;

            .select2-search__field {
              border: 1px dotted $gray03;
              min-width: 100%;
            }
          }
        }

        .select2-container--default.select2-container--focus .select2-selection--multiple {
          border: 0;
          outline: 0;
        }

        .select2-container--default .select2-selection--multiple {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          cursor: pointer;
          
          .select2-search__field {
            cursor: pointer;
          }
        }

        .select2-container--default .select2-selection--multiple .select2-selection__rendered {
          box-sizing: border-box;
          width: 100%;
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
    }

    &-select { 
      &ed-list {
        font-size: 12px;
        font-size: 1.2rem;
      }

      &-area {
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px dotted $main;
        padding-bottom: 20px;
        line-height: 1.1;
        justify-content: space-between;
        flex-wrap: wrap;

        @include is-mobile {
          padding-left: 10px;
          padding-right: 10px;
          padding-bottom: 10px;
        }
      }
  
      &-btn {
        align-self: end;

        .p-data-btn.-bulk {
          margin-left: 25px;
          background-color: $main;
          
          @include is-mobile {
            margin-left: 0;
            margin-right: 0px;
          }

          &.-reset {
            border: 1px solid $black2;
            color: $black2;
            background-color: $white;
          }
        }

        .p-data-btnbox {
          @include is-mobile {
            margin-bottom: 10px;
          }
        }

        .p-data-btnbox + .p-data-btnbox {
          margin-left: 5px;

          @include is-mobile {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      &-toolbox {
        display: flex;
        margin-left: auto;

        @include is-mobile {
          flex-wrap: wrap;
        }
      }
    }

    &-input {
      width: auto;
      line-height: 2;

      @include is-mobile {
        flex-wrap: wrap;
        margin-bottom: 1em;
      }

      input[type='text'].-input-text {
        width: 85px;
        border-color: $gray10;
        margin-right: 10px;

        & + input[type='text'].-input-text {
          margin-left: 10px;
        }
      }
    }
  }
  

  &-content {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mediaquery-max(1240px) {
      justify-content: center;
    }

    @include is-mobile {
      justify-content: left;
    }

    &.-jobs_area,
    &.-jobs,
    &.-area {
      .p-data-select-area {
        border-bottom: 0;
        justify-content: initial;
      }
    }

    &-data.-table,
    &-data-ext.-table,
    &-data.-bar.-wide,
    &-data-ext.-bar.-wide,
    &-data.-circle.-wide,
    &-data-ext.-circle.-wide,
    &-userdata {
      width: auto;
      min-width: 500px;
      overflow-x: auto;
      flex: 1;
      padding: 0 15px;

      @include is-mobile {
        min-width: 400px;
        padding: 0 8px;
      }
    }

    &-data.-circle.-wide {
      width: 100%;
    }

    &-data.-table,
    &-userdata.-table {
      padding-right: 30px;
      width: 100%;

      &.-weekday {
        min-width: 100%;
      }

      @include mediaquery-max(1240px) {
        padding-right: 0;
      }
    }

    &-userdata-wrap {
      width: 100%;
      display: flex;
      position: relative;
      flex-wrap: wrap;

      .-user_report & {
        display: block;
      }


      @include mediaquery-max(1240px) {
        display: block;
        padding-top: 45px;
        margin-top: -45px;
      }

      &.-shrink {
        width: 50%;
      }
      
      &.-shrink .p-report-content-userdata:last-child {
        display: none;
      }

      .-open_user_report {
    

        display: block;

        background-position: center;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2245%22%20height%3D%2233.09%22%3E%3Cpath%20d%3D%22M8%201.99h35v23.1H8V1.99z%22%20fill%3D%22none%22%20stroke%3D%22%230d87b8%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%2F%3E%3Cpath%20d%3D%22M0%2010.09h4v19H0v-19zm0%2019h24v4H0v-4z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-size: 22px 16px;
        width: 22px;
        height: 16px;
        position: absolute;
        z-index: 50;
        top: 16px;
        right: 16px;

        @include mediaquery-max(1240px) {
          top: 60px;
        }
      }
    }

    &.-all {
      display: flex;
    }

    &.-all.-old {
      display: none;
    }

    &.-user {
      display: block;
    }

    &-data {
      width: 500px;
    }

    &-selectarea {
      width: 100%;
    }

  }

  &-child-content {
    display: none;
    border-top: 1px solid $color-pain-border;
  }

  &-box {
    margin-bottom: 20px;

    @include is-mobile {
      margin-bottom: 0;
    }
    
    &-header {
      display: flex;
    }

    &-graph-area,
    &-graph-area-ext {
      height: 500px;

      .-jobs &,
      .-area & {
        height: 600px;
      }
      
      .-jobs_category & {
        height: 800px;
      }
    }

    .u-ttl {
      padding-left: 0;
      height: 50px;
      color: $main;
    }

    .p-data-table {
      &-tr {
        &.-bottom {
          background-color: $table-tr-bottom;

          .p-data-table-td {
            border-top: 1px solid $gray06;
            border-bottom: 1px solid $gray06;
          }
        }
      }
      &-td {
        text-align: center;
        padding: 10px;
        border-bottom: 0;

        @include is-mobile {
          padding: 5px;
        }

        & + .p-data-table-td {
          border-bottom: 0;
        }

        &.-jobs-ranking .-ranking-list {
          word-break: keep-all;
          margin-top: 1em;
          text-align: left;
          padding: .5em;
          border: 1px solid $color-navi-border;
          line-height: 1.3;

          &-item + .-ranking-list-item{
            margin-top: .7em;
          }

          .u-text:first-child {
            @include fz(10);

            color: $gray08;
          }

          .u-text:last-child {
            @include fz(15);
          }

          &:before {
            content: '[業種ランキングTOP3]';
            display: block;
            padding-bottom: 1em;
            color: $color-navi-border;
            @include fz(10);
          }
        }
      }
    }
  }
}



.p-report-child-content-userdata-wrap {
  position: relative;

  .-open_user_report {
    display: block;

    background-position: center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2245%22%20height%3D%2233.09%22%3E%3Cpath%20d%3D%22M8%201.99h35v23.1H8V1.99z%22%20fill%3D%22none%22%20stroke%3D%22%230d87b8%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%2F%3E%3Cpath%20d%3D%22M0%2010.09h4v19H0v-19zm0%2019h24v4H0v-4z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-size: 22px 16px;
    width: 22px;
    height: 16px;
    position: absolute;
    z-index: 50;
    top: 16px;
    right: 16px;

    @include mediaquery-max(1240px) {
      top: 60px;
    }

  }
}
