
// Media

$design-width: 1600px;

// breakpoint
$breakpoint-max: 1024px;
$breakpoint-tablet: 1023px;
$breakpoint-middle: 920px;
$breakpoint-min: 767px;
$breakpoint-extra-min: 374px;

$mac: 1199px;

$humberger: 1400px;

$breakpoint-toppage: 1125px;
$breakpoint-toppage-mobile: 512px;

// max width
@mixin is-design-width() {
  @media screen and (max-width: $design-width) {
    @content;
  }
}

// PC
@mixin is-laptop() {
  @media screen and (min-width: $breakpoint-max) {
    @content;
  }
}

// Humberger Menu
@mixin is-humberger() {
  @media screen and (max-width: $humberger) {
    @content;
  }
}

// Humberger Menu
@mixin is-mac() {
  @media screen and (max-width: $mac) {
    @content;
  }
}

// Tablet Large
@mixin is-tablet() {
  @media screen and (max-width: $breakpoint-max) {
    @content;
  }
}

// Tablet
@mixin is-tablet-small() {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

// footer Tablet
@mixin is-footer-tablet() {
  @media screen and (max-width: $breakpoint-middle) {
    @content;
  }
}

// Mobile
@mixin is-mobile() {
  @media screen and (max-width: $breakpoint-min) {
    @content;
  }
}

// Mobile Small
@mixin is-mobile-small() {
  @media screen and (max-width: $breakpoint-extra-min) {
    @content;
  }
}

// top page
@mixin is-toppage {
  @media screen and (max-width: $breakpoint-toppage) {
    @content;
  }
}

@mixin is-toppage-mobile {
  @media screen and (max-width: $breakpoint-toppage-mobile) {
    @content;
  }
}

// custom
@mixin mediaquery-max($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

// custom
@mixin mediaquery-min($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

// custom
@mixin mediaquery-max-height($breakpoint) {
  @media screen and (max-height: $breakpoint) {
    @content;
  }
}