/*----------------------------------------
parts - input-group
----------------------------------------*/
.p-data-input {
  $this: &;

  & + & {
    margin-top: 20px;
  }
  
  &-group {
    $group: &;

    display: flex;
    width: 100%;

    @include is-mobile {
      display: block;
    }

    #{$this} {
      width: 100%;
    }

    & + & {
      margin-top: 20px;
    }

    &-item {
      width: 100%;
      background-color: $gray09;
      padding: 20px;

      @include is-mobile {
        padding: 15px;
      }


      &.-checkbox {
        padding: 0;
        background-color: $white;
        max-width: 25px;
        display: flex;
        align-items: center;
      }

      &.-btn {
        padding: 0;
        background-color: $white;
        max-width: 100px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        @include is-mobile {
          max-width: 100%;
          justify-content: center;
        }

        &.-vertical {
          flex-wrap: wrap;

          .p-data-btnbox + .p-data-btnbox {
            margin-left: 10px;
          }
        }
      }
    }
  }
}