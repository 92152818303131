.p-data-input-calender {
  width: 100%;
  margin-bottom: 15px;
  
  .table-condensed {
    width: 100%;
  }

  th {
    text-align: center;
    vertical-align: middle;
    font-size: 10px;
    padding-top: 7px;
    padding-bottom: 7px;

    &:first-child {
      color: $red;
    }
  }

  td {
    text-align: center;
    vertical-align: middle;
    font-size: 11px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: rgba($black2, .5);
    border: 1px solid $gray02;

    &:first-child,
    &.is-holiday {
      color: rgba($red, .5);
    }

    &.is-workingday {
      background-color: $paleblue;
      font-weight: bold;

      &.today {
        background-color: $input-telno-color-bg;
      }
    }

    &.active {
      position: relative;

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $sub;
        border-radius: 50%;
      }
    }
  }
}

.datepicker {
  & thead tr:nth-child(2) {
    border-bottom: 1px solid $gray02;
    font-size: 16px;
    font-size: 1.6rem;
    width: 100%;
    
    th {
      padding-bottom: 10px;
    }
  }

  & th {
    &.dow {
      padding-top: 30px;
    }
  }

  & .prev {
    font-size: 0;
    position: relative;
    text-align: left;

    &::after {
      content: "";
      order: 1;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-right: 1px solid $main;
      border-bottom: 1px solid $main;
      transform: translate(0, 0) rotate(135deg);  
    }


    span {
      display: none;
    }
  }

  & .next {
    font-size: 0;
    position: relative;
    text-align: right;

    &::after {
      content: "";
      order: 3;
      display: inline-block;
      border-left: 1px solid $main;
      border-bottom: 1px solid $main;
      width: 8px;
      height: 8px;
      transform: translate(-4px, 0) rotate(-135deg);
    }

    span {
      display: none;
    }
  }

  &-dropdown {
    position: absolute;
    padding: 10px;
    background-color: $white;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .2);

    .datepicker-months td {
      text-align: center;
      border-top: 1px solid $gray10;
      border-left: 1px solid $gray10;

      .month {
        display: inline-block;
        width: 25%;
        border-right: 1px solid $gray10;
        border-bottom: 1px solid $gray10;

        &.active {
          background-color: $main;
          color: $white;
        }
      }
    }

    .datepicker-days {
      th {
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 5px;
        padding-right: 5px;

    
        &:first-child {
          color: $red;
        }

        &.datepicker-switch {
          color: $black;
          font-size: 13px;
          font-size: 1.3rem;
        }

        &.prev, &.next {
          font-size: 0;
        }

      }
    
      td {
        text-align: center;
        vertical-align: middle;
        font-size: 11px;
        padding-top: 7px;
        padding-bottom: 7px;
        color: rgba($black2, .5);
        border: 1px solid $gray02;
    
        &:first-child,
        &.is-holiday {
          color: rgba($red, .5);
        }
    
        &.is-workingday {
          background-color: $paleblue;
          font-weight: bold;
    
          &.today {
            background-color: $input-telno-color-bg;
          }
        }
    
        &.active {
          position: relative;
    
          &::before {
            content: "";
            width: 18px;
            height: 18px;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid $sub;
            border-radius: 50%;
          }
        }
      }
    }

    .datepicker-years {
      .year {
        margin: 0 .25em;
      }
    }
  }

  &-title {
    color: $black;
  }

  .datepicker-days .datepicker-switch {
    font-size: 25px;
    font-size: 2.5rem;
    margin-right: 15px;
    color: transparent;

    .u-text,
    .smonth,
    .year {
      font-size: 16px;
      font-size: 1.6rem;
    }

    .smonth {
      margin-left: 15px;
    }
  }

  &-year {
    order: 2;
  }

  &-month {
    order: 1;
    font-size: 25px;
    font-size: 2.5rem;
    margin-right: 15px;
    
    .u-text {
      font-size: 16px;
      font-size: 1.6rem;
    }
  }

  

  &-calendar {
    width: 100%;
    margin-bottom: 15px;

    .active {
      position: relative;

      &::before {
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid $sub;
        border-radius: 50%;
      }
    }
  }
}