/*----------------------------------------
parts - loading
----------------------------------------*/
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 10000;
  align-items: center;
  justify-content: center;

  &-inner {
    background-color: $main;
    line-height: 0;
    padding: 10px;
    border-radius: 10px;

    &.logo {
      background-color: $main;

      .logo_svg {
        width: 38px;
        height: 38px;

        path {
          fill: $white;
          opacity: 0;
        }
      }
    }
  }
}

//logo
@keyframes fadein {
  0%,80% {
      opacity: 0;
  }

  25% {
    opacity: 1;
  }

  45% {
      opacity: 1;
  }
}

.logo path:nth-child(1) {
  animation: fadein 2s -.36s infinite cubic-bezier(.2,.68,.18,1.08)
}

.logo path:nth-child(2) {
  animation: fadein 2s -.24s infinite cubic-bezier(.2,.68,.18,1.08)
}

.logo path:nth-child(3) {
  animation: fadein 2s -.12s infinite cubic-bezier(.2,.68,.18,1.08)
}

.logo path:nth-child(4) {
  animation: fadein 2s 0s infinite cubic-bezier(.2,.68,.18,1.08)
}

//ball
.ball-pulse-sync>div,.ball-pulse>div {
  width: 15px;
  height: 15px;
  margin: 2px;
  display: inline-block
}

.ball-pulse-sync>div,.ball-pulse>div,.ball-scale>div {
  background-color: #fff;
  border-radius: 100%
}

@-webkit-keyframes scale {
  0%,80% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }

  45% {
      -webkit-transform: scale(.1);
      transform: scale(.1);
      opacity: .7
  }
}

@keyframes scale {
  0%,80% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
  }

  45% {
      -webkit-transform: scale(.1);
      transform: scale(.1);
      opacity: .7
  }
}

.ball-pulse>div:nth-child(1) {
  -webkit-animation: scale .75s -.24s infinite cubic-bezier(.2,.68,.18,1.08);
  animation: scale .75s -.24s infinite cubic-bezier(.2,.68,.18,1.08)
}

.ball-pulse>div:nth-child(2) {
  -webkit-animation: scale .75s -.12s infinite cubic-bezier(.2,.68,.18,1.08);
  animation: scale .75s -.12s infinite cubic-bezier(.2,.68,.18,1.08)
}

.ball-pulse>div:nth-child(3) {
  -webkit-animation: scale .75s 0s infinite cubic-bezier(.2,.68,.18,1.08);
  animation: scale .75s 0s infinite cubic-bezier(.2,.68,.18,1.08)
}

.ball-pulse>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes ball-pulse-sync {
  33% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }

  66% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

@keyframes ball-pulse-sync {
  33% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
  }

  66% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

.ball-pulse-sync>div:nth-child(1) {
  -webkit-animation: ball-pulse-sync .6s -.14s infinite ease-in-out;
  animation: ball-pulse-sync .6s -.14s infinite ease-in-out
}

.ball-pulse-sync>div:nth-child(2) {
  -webkit-animation: ball-pulse-sync .6s -.07s infinite ease-in-out;
  animation: ball-pulse-sync .6s -.07s infinite ease-in-out
}

.ball-pulse-sync>div:nth-child(3) {
  -webkit-animation: ball-pulse-sync .6s 0s infinite ease-in-out;
  animation: ball-pulse-sync .6s 0s infinite ease-in-out
}

.ball-pulse-sync>div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes ball-scale {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0
  }
}

@keyframes ball-scale {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0
  }
}