
/*----------------------------------------
parts - selectbox
----------------------------------------*/
.p-data {

  // フォームパーツ：セレクトボックス
  &-select {
    width: calc(100% - 200px);
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-left: 1px solid $black2;
      border-bottom: 1px solid $black2;
      transform: rotate(-45deg) translateY(-100%);
      position: absolute;
      right: 10px;
      top: 12px;
      z-index: 0;
    }

    select {
      height: 25px;
      padding-left: 12px;
      border: 1px solid $black2;
      display: block;
      width: 100%;
      position: relative;
      z-index: 1;
      background: none;
    }
  }

}