/*----------------------------------------
parts - base
----------------------------------------*/

.p-data {
  position: relative;

  &-area {
    padding: 0 20px 20px;
    overflow: auto;
    position: relative;

    @include is-mobile {
      padding: 10px 15px 15px;
    }

    // テーブルリストをスクロールさせない
    &.-noscroll {
      max-height: 100%;
      height: auto;
    }
  }
}