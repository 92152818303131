
/*----------------------------------------
parts - title
----------------------------------------*/

.p-data {
  
  .u-ttl {
    display: flex;
    height: 60px;
    padding-left: 20px;
    align-items: center;

    &.-solid {
      margin-bottom: 20px;
      border-bottom: 1px solid $color-ttl-border;
    }

    &.-dotted {
      margin-bottom: 20px;
      border-bottom: 1px dotted $main;
    }
  }
}
