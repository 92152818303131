
/*----------------------------------------
parts - input
----------------------------------------*/
.p-data {
 
  // フォームパーツ：テキスト
  &-input {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 176px);

    @include is-mobile {
      width: 100%;
    }
    label {
      &.-modal {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 25px;
        font-size: 13px;
        font-size: 1.3rem;
        min-width: 110px;
        text-align: right;
        padding-right: 15px;

        @include is-mobile() {
          text-align: left;
          display: block;
        }        

      }
    }

    input[type='text'],
    input[type='password'],
    input[type='number'],
    .p-data-selectbox {
      &.-input-text,
      &.-input-select {
        height: 25px;
        padding: 0 10px;
        font-size: 12px;
        border: 1px solid $black2;
        width: 100%;
      }

      &.-require {
        border: 1px solid $red;
      }
    }

    // 2カラム
    &.-two-column {
      display: flex;
      
      @include is-mobile {
        display: block;
      }

      input[type='text'],
      input[type='password'],
      input[type='number'],
      .p-data-selectbox {
        &.-input-text,
        &.-input-select {
          width: calc(50% - 5px);
        }
      }

      input[type='text'].-input-text + input[type='text'].-input-text,
      input[type='password'].-input-text + input[type='password'].-input-text,
      input[type='number'].-input-text + input[type='number'].-input-text {
        margin-left: 10px;
      }
    }

    &-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      line-height: 1;
      padding-bottom: 20px;

      & + & {
        padding-top: 20px;
        border-top: 1px solid $gray06;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &-btn {
      display: flex;
      margin-left: 15px;

      @include is-mobile {
        margin: 0 auto;
      }

      .p-data-btnbox {
        min-width: 72px;
        display: flex;
        align-items: center;
        height: 25px;

        @include is-mobile {
          height: auto;
          margin-top: 10px;
        }
      }

      .p-data-btnbox + .p-data-btnbox {
        margin-left: 10px;
      }
    }
  }
}