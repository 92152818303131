
/*----------------------------------------
parts - modal
----------------------------------------*/

.p-modal {
  width: 60%;
  min-width: 768px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;

  @include is-humberger() {
    top: 45px;
    transform: translate(-50%, 0);
  }


  @include is-mobile() {
    width: 100%;
    min-width: 320px;
  }
  
  &-area {
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 1000;
    display: none;
  }

  &-close {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    top: 20px;
    right: 25px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 10px;
      left: 0;
      width: 100%;
      height: 0;
      border-top: 1px solid $black2;
    }

    &::after {
      top: 0;
      left: 10px;
      transform-origin: left;
      transform: rotate(90deg);
    }
  }

  .l-pain {
    width: 100%;
  }

  .p-data-area {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    overflow: initial;

    .p-data-input {
      width: 100%;

      @include is-mobile() {
        display: block;
      }

      &-area {
        input[readonly] {
          border: 0;
        }
        .p-data-input-textarea {
          margin-top: 20px;
        }
        .p-data-input-duration,
        .p-data-input-textarea {
          width: calc(100% - 110px);

          &-select {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            & + .p-data-input-duration-select {
              margin-top: 20px;
            } 

            label.-modal {
              max-width: 3em;
              min-width: 90px;
            }
            
            input[type='text'].-input-text,
            input[type='number'].-input-text,
            input[type='password'].-input-text {
              width: 238px;
              margin-right: 10px;

              & + input[type='text'].-input-text,
              & + input[type='number'].-input-text,
              & + input[type='password'].-input-text {
                margin-right: 0;
                margin-left: 0;
              }
            }
          }
        }
      }
    }

    .p-data-input-area {
      width: 50%;
      border-bottom: 1px solid $gray06;
      margin-bottom: 20px;

      &:nth-child(even) {
        padding-left: 15px;

        @include is-mobile() {
          padding-left: 0;
        }
      }

      &:nth-last-child(-n+2) {
        border-bottom: 0;
      }

      & + .p-data-input-area {
        padding-top: 0;
        border-top: 0;
      }

      &.-wide {
        width: 100%;
      }

      &.-wide:nth-child(even) {
        padding-left: 0;
      }

      @include is-mobile() {
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      input[type='text'],
      input[type='number'], 
      input[type='password'],
      .p-data-selectbox {
        width: calc(100% - 110px);

        @include is-mobile() {
          width: 100%;
        }
      }

      #tel_no + .u-text {
        text-align: right;
        margin-top: .5em;
        width: 100%;
        @include fz(12);
      }

      .select2-container {
        width: calc(100% - 110px);

        @include is-mobile() {
          width: 100%;
        }

        .select2-selection--multiple {
          border-radius: 0; 
        }

        .select2-search--inline {
          width: 100%;
        }
      }
    }
    
    .p-data-checkbox {
      height: 25px;
    }
    .p-data-textarea {
      width: calc(100% - 110px);

      @include is-mobile() {
        width: 100%;
      }
    }

    .p-data-btn {
      height: auto;
      display: flex;
      justify-content: space-evenly;

      .u-btn {
        width: 33%;
        border: 0;

        &.-reset {
          background-color: $gray03;
        }
      }
    }
  }
}

.a-custom_modal .-lay .-row.p-data-btn.-btn .u-btn {
  width: 100%;
}

.-edit-call_history {
  .p-data-table {
    margin-bottom: 1em;

    .-key {
      font-weight: bold;
      width: 8em;
    }
  }
  
  .p-data-input {
    margin-bottom: 2em;

    &-area.-wide {
      margin-top: 1em;
    }

    h4.-modal {
      font-weight: bold;
      width: 100%;
      padding: 1em 0;
    }
  }
}


.select2-container {
  z-index: 1000;
}