
/*----------------------------------------
Media
----------------------------------------*/

.is-pc {
  @include is-tablet {
    display: none;
  }

  @include is-mobile {
    display: none;
  }
}

.is-sp {
  display: none;

  @include is-mobile {
    display: block;
  }
}

.is-tablet {
  display: none;

  @include is-tablet {
    display: block;
  }
}

.is-pc-tablet {
  display: none;

  @media screen and (min-width: $breakpoint-min + 1){
    display: block;
  }
}