
/*input*/

input[type="radio"] + span,
input[type="checkbox"] + span {
  position: relative;
  display: inline-block;
  margin: 0 25px 0 0;
  padding: 0 0 0 28px;
  line-height: 1.5;
  text-align: left;
  cursor: pointer;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid $gray03;
  background: #fff;
  vertical-align: sub;
  content: "";
}

input[type="radio"] + span::after,
input[type="checkbox"] + span::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 9px;
  height: 9px;
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

input[type="radio"]:checked + span::after,
input[type="checkbox"]:checked + span::after {
  opacity: 1;
}

input[type="radio"] + span::before {
  border-radius: 50%;
}

input[type="radio"] + span::after {
  border-radius: 50%;
  background: $gray02;
}

input[type="checkbox"] + span::before {
  border-width: 2px;
  border-radius: 1px;
}

input[type="checkbox"] + span::after {
  top: 2px;
  left: 6px;
  width: 5px;
  border: solid #feb300;
  border-width: 0 3px 3px 0;
  color: #feb300;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
