
/*----------------------------------------
parts - table
----------------------------------------*/
.p-data {

  &-area.-table:not(.-noscroll) {
    height: calc(100vh - 340px);

    .-user & {
      height: auto;
    }
  }

  // フォームパーツ：リストテーブル
  &-table {
    width: 100%;
    border-top: 1px solid $gray06;
    
    &-tr:nth-child(even) {
      background-color: $table-tr-even;

      .-loader-icon,
      .-loader-icon::after {
        background-color: $table-tr-even;
      }
    }
   /* 
    &-tr:nth-child(1) th {
      top: 45px;
    }*/

    &-tr:nth-child(2) th {
      //top: calc(45px + 1.5em);

      &:first-child {
        border-left: 1px solid $gray06;
      }
    }

    &-tr.-separate td {
      border-top: 1px solid $gray06;
    }

    &-tr.-cancel,
    &-tr.-closing {
      opacity: .5;

      & td {
        background-color: $gray03;
      }
    }

    &-th,
    &-td {
      padding: 5px 7px;
      letter-spacing: 0;
      border-bottom: 1px solid $gray06;
      line-height: 1.1;

      @include is-mobile {
        padding: 5px 7px;
      }

      & + & {
        border-left: 1px solid $gray06;
      }

      // テーブルリストのtoggleアイコン
      &.-check {
        text-align: center;
        position: relative;
        line-height: 0;
        font-size: 8px;
        width: 80px;

        input[type='checkbox'] {
          position: absolute;
          box-sizing: border-box;
          height: 0;
          width: 0;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          font-size: inherit;
          margin: 0;
          border: none;
          z-index: 0;
          cursor: pointer;
        }

        label {
          display: inline-block;
          position: relative;
          box-sizing: border-box;
          cursor: pointer;
          width: 5em;
          height: 2.5em;
          border-radius: 50px;
          background: $white;
          border: .2em solid $gray02;
          transition: all .3s ease;

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0.18em;
            left: .1em;
            width: 1.8em;
            height: 1.8em;
            border-radius: 2em;
            background: $gray02;
            transition: .3s ease;
          }
        }

        input:checked + label {
          border: .2em solid $main;
        }

        input:checked + label:before {
          background: $main;
          transform: translateX(2.5em);
        }
      }
    }



    // デフォルトではposition:stickyでスクロールする
    &-th,
    &-th.-check {
      font-weight: bold;
      text-align: center;
      position: sticky;
      //top: 0;
      vertical-align: middle;
      background-color: $white;
      z-index: 2;

      @include is-tablet() {
        position: relative;
        top: auto;
      }

      // スクロールさせない
      .-noscroll & {
        position: relative;
        top: auto;
      }
    }


    &-td {
      vertical-align: middle;
      max-width: 200px;

      &[rowspan] {
        border: 1px solid $gray06;
        border-left: 0;
        background-color: $white;
        width: 10em;
      }

      // ユーザー管理の場合名前にアイコン表示
      &.is-user::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-image: url(data:image/svg+xml;charset=utf8,%3Csvg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2010%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2262%22%20height%3D%2262%22%3E%3Ccircle%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%201%22%20cx%3D%2231%22%20cy%3D%2231%22%20r%3D%2231%22%20fill%3D%22%230d87b8%22%2F%3E%3Cpath%20data-name%3D%22%E3%82%B7%E3%82%A7%E3%82%A4%E3%83%97%201%22%20d%3D%22M34.96%2035.135a10.77%2010.77%200%2010-7.92%200c-6.39%201.63-11.1%207.1-11.1%2013.576h2.96c0-6.116%205.43-11.091%2012.1-11.091s12.1%204.976%2012.1%2011.091h2.96c0-6.478-4.71-11.945-11.1-13.576zm-11.77-10.1A7.81%207.81%200%201131%2032.921a7.855%207.855%200%2001-7.81-7.882z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px 20px;
        vertical-align: middle;
        content: "";
      }

      // テーブルリストの変更アイコン
      &.-checkbox {
        text-align: center;
        width: 3.5em;
      }

      // テーブルリストの変更アイコン
      &.-edit {
        text-align: center;
        width: 3.5em;

        span {
          display: inline-block;
          text-indent: -10000px;
          overflow: hidden;
          width: 15px;
          height: 15px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228.06%22%20height%3D%2228.03%22%3E%3Cpath%20d%3D%22M.01%2022.17v5.84h5.84l17.22-17.22-5.84-5.84zM27.59%206.28a1.569%201.569%200%20000-2.2L23.94.44a1.539%201.539%200%2000-2.19%200L18.9%203.29l5.84%205.84z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 15px 15px;
          vertical-align: middle;
        }
      }

      // テーブルリストの削除アイコン
      &.-del {
        text-align: center;
        width: 3.5em;

        span {
          display: inline-block;
          text-indent: -10000px;
          overflow: hidden;
          width: 14px;
          height: 14px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2228%22%20height%3D%2228.252%22%20viewBox%3D%220%200%2027.75%2028%22%3E%3Cpath%20d%3D%22M5.135%2024.49a3.508%203.508%200%20003.49%203.5h10.5a3.508%203.508%200%20003.49-3.5V7.01H5.135v17.48zm19.23-22.73h-5.24L17.375.01h-7l-1.75%201.75h-5.24v3.5h20.98v-3.5z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 14px 14px;
          vertical-align: middle;
        }
      }

      &.-record_file {
        text-align: center;
        line-height: 1;

        .js-dl-btn,
        .-dl-btn {
          display: inline-block;
          text-indent: -10000px;
          overflow: hidden;
          width: 21px;
          height: 14px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220.75%22%20height%3D%2213.938%22%3E%3Cpath%20d%3D%22M10.375%2011.032a.632.632%200%2001-.632-.632V.626a.632.632%200%20111.264%200v9.775a.632.632%200%2001-.632.631zm0%20.025a.631.631%200%2001-.434-.172L6.9%208.015a.632.632%200%2001.868-.92l2.607%202.461%202.607-2.461a.632.632%200%2011.868.92l-3.041%202.871a.631.631%200%2001-.434.172zm4.582-7.748v1.265h4.518v8.106h-18.2V4.575h4.516V3.31H.011v10.635h20.727V3.31h-5.781z%22%20fill%3D%22%230d87b8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 21px 14px;
          vertical-align: middle;
        }
      }

      //音声変換ボタン
      &-convertbtn {
        overflow: hidden;
        display: block;
        padding: 2px 0;
        
        &-view {
          display: inline-block;
          text-indent: -10000px;
          overflow: hidden;
          width: 28px;
          height: 20px;
          background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2238%22%20height%3D%2234%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22%230D87B8%22%20d%3D%22M33.257%2025.949c3.046-2.801%204.716-6.393%204.716-10.171%200-4.12-1.873-7.982-5.528-10.875C28.934%202.54%2024.276.484%2019.331.484c-4.946%200-9.604%202.056-13.115%204.419C2.652%207.796.689%2011.658.689%2015.778s1.963%207.982%205.527%2010.875c3.511%202.849%208.169%205.057%2013.115%205.057%202.813%200%205.509-1.137%208.02-2.12l6.984%204.162a.873.873%200%200%200%201.001-.073c.285-.232.395-.069.274-.965l-2.353-6.765Zm-5.383%201.916a.871.871%200%200%200-.448-.123c-.114%200-.228.022.474.066-3.223%201.004-5.24%201.514-8.569%201.514-9.171%200-16.893-6.076-16.893-13.544%200-7.468%207.722-13.544%2016.893-13.544%209.314%200%2016.892%206.076%2016.892%2013.544%200%203.442-1.617%206.724-4.554%209.422-.273.055-.374.432-.256.772l1.755%205.228-5.294-3.335Z%22%2F%3E%3C%2Fsvg%3E');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px 18px;
          vertical-align: middle;
        }
      }
    }
  }
}


.tablesorter-default .tablesorter-header {
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  background-position: center right;
  background-repeat: no-repeat;
  cursor: pointer;
  white-space: normal;

  &:focus {
    outline: 0;
    background-color: $input-telno-color-bg;
  }

  &.sorter-false {
    background-image: none;
  }
}

.tablesorter-default thead .headerSortUp, .tablesorter-default thead .tablesorter-headerAsc, .tablesorter-default thead .tablesorter-headerSortUp {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
}

.tablesorter-default thead .headerSortDown, .tablesorter-default thead .tablesorter-headerDesc, .tablesorter-default thead .tablesorter-headerSortDown {
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
}